<template>
    <data-list
            ref="dataList"
            class="content"
            name="流程"
            add-btn="新增流程"
            add-route="ProcessAdd"
            url="/apply-temp"
            :query="query"
            :columns="columns"
            @edit="showEdit"
            @rowClick="showDetail">
        <template #title>
            <span>流程列表</span>
        </template>
        <template v-slot:query="{form}">
            <a-form-model-item label="">
                <a-input placeholder="输入流程名称查询" v-model="form.name"></a-input>
            </a-form-model-item>
        </template>
    </data-list>
</template>

<script>
    export default {
        name: "ProcessList",
        data() {
            return {
                columns: [
                    {title: '流程名称', dataIndex: 'name'},
                    {
                        title: '群团名称',
                        dataIndex: 'group',
                        customRender: text => {
                            return <span>{text ? text.name : '无'}</span>
                        }
                    },
                     {
                        title: '申请方式',
                        dataIndex: 'is_personal',
                        customRender: text => {
                            return <span>{text==0 ? '团体社员' :text==1?'个人社员':'无' }</span>
                        }
                    },
                ],
                query: '&expand=group'
            }
        },
        computed: {
            update() {
                return this.$store.getters.update;
            }
        },
        watch: {
            update(val) {
                if(val && val.type == 'process') {
                    this.$refs.dataList.getList();
                    this.$store.commit("changeUpdate", null);
                }
            },
        },
        methods: {
            showDetail({item}, edit) {
                let type = 'process'
                this.$store.commit("setDetail", {type, obj: item})
                this.$router.push({
                    name: 'ProcessDetail',
                    params: {type, id: item.id},
                    query: edit ? {edit: '1'} : {}
                })
            },
            showEdit(item) {
                this.showDetail({item}, true);
            }
        }
    }
</script>

<style scoped>

</style>
